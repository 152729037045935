import React from 'react'
import Header from '../components/Header'
import Nav from '../components/Nav'

function Home() {
  return (
    <div>
        

<div className="wrapper">

  <Header/>

  <Nav/>

{/* <!-- Content Wrapper. Contains page content --> */}
<div className="content-wrapper">
    <div className="container-full">
      {/* <!-- Content Header (Page header) -->	   */}
      <div className="content-header">
          <h3 style={{ fontFamily: "'Abril Fatface', cursive", fontSize: "1.5rem", marginTop: "40px", marginBottom: "40px" }}>
              Hi, Olamilekan 👋
            </h3>
          
          {/* <button>Fund Wallet</button> */}
          
        {/* <!--	<ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol> --> */}
      </div>

      {/* <!-- Main content --> */}
          <section className="content">
              <div className="row">	



      {/* <!--			<div className="col-xxxl-9 col-xl-8 col-12">
                      <div className="box bg-transparent no-shadow">
                          <div className="box-header">
                              <h4 className="box-title">Summary</h4>
                          </div>
                          <div className="box-body">
                              <div id="summary-chart"></div>
                          </div>
                      </div>
                  </div> --> */}
                  {/* <!-- <div className="col-xxxl-3 col-xl-4">
                      <div className="box">
                          <div className="box-body d-flex p-0">
                              <div className="flex-grow-1 px-30 pt-30 pb-200 flex-grow-1 bg-img min-h-450" style="background-position: right bottom; background-size: 80% auto; background-image: url(../images/gallery/img-cy.png)">
                                  <h3 className="mb-0 font-weight-700">Support</h3>
                                  <p className="font-size-18">
                                      We always be in touch!
                                  </p>
                                  <a href="#" className="btn btn-success"><i className="ti-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                  </div> --> */}
                              
                  
                  
                  <div className="col-xl-12 col-12">
                      <div className="row">
                      
                      
                   
          <div className="col-12 col-lg-3">
              <div className="box box-body pull-up p-10" style={{ borderTop: "2px solid green" }}>
                <div className="media align-items-center p-0" style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between" }}>
                  <div className="text-center" style={{ margin: "0 1px" }}>
                  
                  <div style={{ border: "1px dashed #fff", borderRadius: "50%", padding: "10px", boxShadow: "rgb(17 12 46 / 15%) 0px 48px 100px 0px" }}>
                      <img src="../images/wallet-icon.png" width="35px" />
                  </div>
                   {/* <!-- <a href="#"><i className="cc XRP mr-5" title="XRP"></i></a> --> */}
                  </div>
                  <div style={{ margin: "0 1px" }}>
                    <h4 className="no-margin text-bold" style={{ fontFamily: "'Nunito Sans', sans-serif", fontWeight: "bolder", color: "#475f7b" }}>
                    My Wallet
                    </h4>
                    
                  </div>
                </div>
                <div className="flexbox align-items-center mt-10" style={{ justifyContent: "flex-end" }}>
                  {/* <!-- <div>
                    <p className="no-margin font-weight-600"><span className="text-warning">$5000</span></p>
                    <p className="no-margin">Comission</p>
                  </div> --> */}
                                      
                  
                  <div className="text-right">
                    <p className="no-margin font-weight-600"><span className="text-dark" style={{ fontFamily: "'Nunito Sans', sans-serif;" }}>Available Balance</span></p>
                    <p className="no-margin" style={{ fontSize: "18px" }}><b>₦5000.00</b></p>
                  </div>
                </div>
              </div>			
            </div>
                          
                          
                      



        <div className="col-12 col-lg-3">
              <div className="box box-body bg-hexagons-dark pull-up p-10" style={{ borderTop: "2px solid" }}>
                <div className="media align-items-center p-0" style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between" }}>
                  <div className="text-center" style={{ margin: "0 1px" }}>
                  
                  <div style={{ border: "1px dashed #fff", borderRadius: "50%", padding: "10px", boxShadow: "rgb(17 12 46 / 15%) 0px 48px 100px 0px" }}>
                      <img src="../images/exchange.png" width="35px" />
                  </div>
                  
                   {/* <!-- <a href="#"><i className="cc XRP mr-5" title="XRP"></i></a> --> */}
                  </div>
                  <div style={{ margin: "0 1px" }}>
                    <h4 className="no-margin text-bold" style={{ fontFamily: "'Nunito Sans', sans-serif", fontWeight: "bolder", color: "#475f7b" }}>
                    Referral
                    </h4>
                    
                  </div>
                </div>
                <div className="flexbox align-items-center mt-10" style={{ justifyContent: "flex-end" }}>
                  {/* <!-- <div>
                    <p className="no-margin font-weight-600"><span className="text-warning">$5000</span></p>
                    <p className="no-margin">Comission</p>
                  </div> --> */}                                    
                  
                  <div className="text-right">
                    <p className="no-margin font-weight-600"><span className="text-dark" style={{ fontFamily: "'Nunito Sans', sans-serif;" }}>Amount Earned</span></p>
                    <p className="no-margin" style={{ fontSize: "18px" }}><b>₦0.00</b></p>
                  </div>
                </div>
              </div>			
            </div>
          


                      
                  
                  {/* <div className="col-12 col-lg-3">
                      <div className="box box-body pull-up">
                          <div className="d-flex justify-content-between">
                            <div className="p-0">
                              <div className="text-center">
                                <a href="#"><i className="cc EOS mr-5" title="EOS"></i></a>
                              </div>
                              <div>
                                <h3 className="no-margin text-bold">EOS</h3>
                                <span>Exchange</span>
                              </div>
                            </div>
                            <div>
                              <div>
                                <p className="no-margin font-weight-600"><span className="text-warning">$2.7</span> / $9.7</p>
                                <p className="no-margin">Sponsored</p>
                              </div>
                              <div className="mt-10">
                                <p className="no-margin font-weight-600"><span className="text-warning">87%</span></p>
                                <p className="no-margin">5d left</p>
                              </div>
                            </div>
                          </div>
                      </div>			
                  </div> */}




                          {/* <div className="col-12 col-lg-3">
                              <div className="box box-body pull-up">
                                  <div className="d-flex justify-content-between">
                                    <div className="p-0">
                                      <div className="text-center">
                                        <a href="#"><i className="cc LSK mr-5" title="LSK"></i></a>
                                      </div>
                                      <div>
                                        <h3 className="no-margin text-bold">Lisk</h3>
                                        <span>Trading</span>
                                      </div>
                                    </div>
                                    <div>
                                      <div>
                                        <p className="no-margin font-weight-600"><span className="text-warning">$7.9</span> / $7.0</p>
                                        <p className="no-margin">Sponsored</p>
                                      </div>
                                      <div className="mt-10">
                                        <p className="no-margin font-weight-600"><span className="text-warning">74%</span></p>
                                        <p className="no-margin">5d left</p>
                                      </div>
                                    </div>
                                </div>
                              </div>			
                          </div> */}
                      </div>	
                                
                  </div>
 
 
 
                  


		<section className="content">
			<div className="row">

			  <div className="col-lg-6 col-12">
				<div className="box" style={{ backgroundColor: "#eef2f480" }}>
        {/* <div class="box-body"> */}
        <div className="row">
                                
          <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://assets.jiji.ng/art/attributes/categories/vehicles.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">Buy Data</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>
          {/* ----- */}

          <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://assets.jiji.ng/art/attributes/categories/real-estate.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">Buy Airtime</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

            {/* --- */}


              <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://assets.jiji.ng/art/attributes/categories/mobile.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">Cable</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>



              {/* ---------------------------------- */}


              <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://assets.jiji.ng/art/attributes/categories/electronics.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">Electricity</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>
          {/* ----- */}

          <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://assets.jiji.ng/art/attributes/categories/home.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">Smile</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

            {/* --- */}


              <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://upload.wikimedia.org/wikipedia/en/d/d9/Waec_logo.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">WAEC PIN</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

{/* ----- */}


            <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://upload.wikimedia.org/wikipedia/en/b/bd/Neco_official_banner.jpg" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">NECO PIN</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>
          {/* ----- */}

          <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" src="https://assets.jiji.ng/art/attributes/categories/babies.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">Recharge PIN</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>

            {/* --- */}


              <div className="col-4 col_padding">
                <div className="bg-white">

                  <div className="text-center pt-3">
                    <a href="#">
                      <img className="service-grid" width={50} src="https://assets.jiji.ng/art/attributes/categories/agriculture.png" alt=""/>
                    </a>
                    <h5 className="mt-0 mb-0 pb-3 service-font"><a href="#">Spectranet</a></h5>
                    {/* <span>Topup</span> */}
                  </div>
                </div>
              </div>





              </div></div>
				  {/* <div className="box-body">
					<p>Basic Box without header</p>
					<p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas.</p>
				  </div> */}
				</div>
			  {/* </div> */}
      </div>
  </section>


                  
                  
                  
                  
                  
      <div className="content">


      <h3 style={{ fontFamily: "'Abril Fatface', cursive", fontSize: "1.5rem", marginTop: "40px", marginBottom: "40px" }}>
              Our Services 👇
            </h3>  


          <div className="row">
                                
               <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">

                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="http://localhost:3000/dashboard/images/data.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Data</a></h5>
                          <span>Topup</span>
                        </div>
                      </div>
                    </div>	
                    
                    
                    
                    
                   
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">
  
                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="http://localhost:3000/dashboard/images/airtime.svg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Airtime</a></h5>
                          <span>Topup</span>
                        </div>
                      </div>
                    </div>
                   
                    
                    
                    
                    
                    
                                         
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">

                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="../images/avatar/2.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Andrew</a></h5>
                          <span>Full Stack Developer</span>
                        </div>
                      </div>
                    </div>
                    
                    
                    
                    
                    
                    
                    
                                         
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">

                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="../images/avatar/2.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Andrew</a></h5>
                          <span>Full Stack Developer</span>
                        </div>
                      </div>
                    </div>
                    
                    
                    
                                         
                    <div className="col-6 col-lg-4">
                      <div className="box box-body bg-hexagons-dark pull-up">
                        <div className="flexbox align-items-center">
                          <label className="toggler toggler-yellow">
                            <input type="checkbox"/>
                            <i className="fa fa-star"></i>
                          </label>
                          <div className="dropdown">
                            <a data-toggle="dropdown" href="#" aria-expanded="false"><i className="fa fa-cog"></i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-user"></i> Profile</a>
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-comments"></i> Messages</a>
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-phone"></i> Call</a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="#"><i className="fa fa-fw fa-remove"></i> Remove</a>
                            </div>
                          </div>
                        </div>
                        <div className="text-center pt-3">
                          <a href="#">
                            <img className="avatar avatar-xxl" src="../images/avatar/2.jpg" alt=""/>
                          </a>
                          <h5 className="mt-15 mb-0"><a href="#">Andrew</a></h5>
                          <span>Full Stack Developer</span>
                        </div>
                      </div>
                    </div>
                    
                    
                    

                    
                  </div>
              </div>	
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  
                  <div className="col-12">
                      <div className="box">
                        <div className="box-body">
                            <ul id="webticker-3">
          
          
          
          
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">ZRX<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -1.33%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">0.26877 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">11,435</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">ETH<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +0.57%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">159.73 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">12,456</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">LTC<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +1.30%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">73.42 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">66,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">ETC<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -0.19%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">5.719 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">22,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">


                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">BCH<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +0.42%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">267.72 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">34,654</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">XRP<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -0.50%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">0.2974 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">12,345</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">BTC<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +3.15%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">5,497.49 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">56,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">REP<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -2.52%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">20.13 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">23,569</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">EOS<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +1.58%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">4.757 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">78,879</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">ZRX<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -2.07%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">0.268135 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">65,908</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">ETH<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +0.47%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">159.55 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">12,234</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">LTC<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +1.28%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">73.40 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">34,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">ETC<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -0.44%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">5.715 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">67,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">BCH<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +0.43%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">268.00 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">87,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">XRP<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -0.54%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">0.2970 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">13,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">BTC<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-success"> +3.00%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">5,490.01 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">43,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li> 
                              <li className="br-1">
                                  <div className="mx-20">
                                      <div className="d-flex justify-content-center">
                                          <h6 className="font-weight-300 mr-5">XLM<span className="px-5">|</span>BTC </h6>
                                          <h6 className="font-weight-300 text-danger"> -0.97%</h6>
                                      </div>
                                      <div className="d-block text-center">
                                          <h3 className=" font-weight-400 my-0">0.099065 <span>USD</span></h3>
                                          <p className="mb-0"><span className="font-weight-300">Volum</span><span className="px-5">23,601</span><span>BTC</span></p>
                                      </div>
                                  </div>
                              </li>  

                            </ul>
                        </div>
                      </div>
                  </div>
              </div>						
          </section>
      {/* <!-- /.content --> */}
    </div>
</div>
{/* <!-- /.content-wrapper --> */}
<footer className="main-footer">
  <div className="pull-right d-none d-sm-inline-block">
      <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
        <li className="nav-item">
          <a className="nav-link" href="javascript:void(0)">FAQ</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Purchase Now</a>
        </li>
      </ul>
  </div>
    &copy; 2020 <a href="../../../index.htm">Multi-Purpose Themes</a>. All Rights Reserved.
</footer>

{/* <!-- Control Sidebar --> */}
<aside className="control-sidebar">
    
  <div className="rpanel-title"><span className="pull-right btn btn-circle btn-danger"><i className="ion ion-close text-white" data-toggle="control-sidebar"></i></span> </div>  
  {/* <!-- Create the tabs --> */}
  <ul className="nav nav-tabs control-sidebar-tabs">
    <li className="nav-item"><a href="#control-sidebar-home-tab" data-toggle="tab">Chat</a></li>
    <li className="nav-item"><a href="#control-sidebar-settings-tab" data-toggle="tab">Todo</a></li>
  </ul>
  {/* <!-- Tab panes --> */}
  <div className="tab-content">
    {/* <!-- Home tab content --> */}
    <div className="tab-pane" id="control-sidebar-home-tab">
        <div className="flexbox">
          <a href="javascript:void(0)" className="text-grey">
              <i className="ti-more"></i>
          </a>	
          <p>Users</p>
          <a href="javascript:void(0)" className="text-right text-grey"><i className="ti-plus"></i></a>
        </div>
        <div className="lookup lookup-sm lookup-right d-none d-lg-block">
          <input type="text" name="s" placeholder="Search" className="w-p100"/>
        </div>
        <div className="media-list media-list-hover mt-20">
          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-success" href="#">
              <img src="../images/avatar/1.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Tyler</strong></a>
              </p>
              <p>Praesent tristique diam...</p>
                <span>Just now</span>
            </div>
          </div>

          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-danger" href="#">
              <img src="../images/avatar/2.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Luke</strong></a>
              </p>
              <p>Cras tempor diam ...</p>
                <span>33 min ago</span>
            </div>
          </div>

          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-warning" href="#">
              <img src="../images/avatar/3.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Evan</strong></a>
              </p>
              <p>In posuere tortor vel...</p>
                <span>42 min ago</span>
            </div>
          </div>

          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-primary" href="#">
              <img src="../images/avatar/4.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Evan</strong></a>
              </p>
              <p>In posuere tortor vel...</p>
                <span>42 min ago</span>
            </div>
          </div>			
          
          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-success" href="#">
              <img src="../images/avatar/1.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Tyler</strong></a>
              </p>
              <p>Praesent tristique diam...</p>
                <span>Just now</span>
            </div>
          </div>

          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-danger" href="#">
              <img src="../images/avatar/2.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Luke</strong></a>
              </p>
              <p>Cras tempor diam ...</p>
                <span>33 min ago</span>
            </div>
          </div>

          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-warning" href="#">
              <img src="../images/avatar/3.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Evan</strong></a>
              </p>
              <p>In posuere tortor vel...</p>
                <span>42 min ago</span>
            </div>
          </div>

          <div className="media py-10 px-0">
            <a className="avatar avatar-lg status-primary" href="#">
              <img src="../images/avatar/4.jpg" alt="..."/>
            </a>
            <div className="media-body">
              <p className="font-size-16">
                <a className="hover-primary" href="#"><strong>Evan</strong></a>
              </p>
              <p>In posuere tortor vel...</p>
                <span>42 min ago</span>
            </div>
          </div>
            
        </div>

    </div>
    {/* <!-- /.tab-pane --> */}
    
    
    
    
    
    
    {/* <!-- Settings tab content --> */}
    <div className="tab-pane" id="control-sidebar-settings-tab">
        <div className="flexbox">
          <a href="javascript:void(0)" className="text-grey">
              <i className="ti-more"></i>
          </a>	
          <p>Todo List</p>
          <a href="javascript:void(0)" className="text-right text-grey"><i className="ti-plus"></i></a>
        </div>
      <ul className="todo-list mt-20">
          <li className="py-15 px-5 by-1">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_1" className="filled-in"/>
            <label for="basic_checkbox_1" className="mb-0 h-15"></label>
            {/* <!-- todo text --> */}
            <span className="text-line">Nulla vitae purus</span>
            {/* <!-- Emphasis label --> */}
            <small className="badge bg-danger"><i className="fa fa-clock-o"></i> 2 mins</small>
            {/* <!-- General tools such as edit or delete--> */}
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_2" className="filled-in"/>
            <label for="basic_checkbox_2" className="mb-0 h-15"></label>
            <span className="text-line">Phasellus interdum</span>
            <small className="badge bg-info"><i className="fa fa-clock-o"></i> 4 hours</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5 by-1">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_3" className="filled-in"/>
            <label for="basic_checkbox_3" className="mb-0 h-15"></label>
            <span className="text-line">Quisque sodales</span>
            <small className="badge bg-warning"><i className="fa fa-clock-o"></i> 1 day</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_4" className="filled-in"/>
            <label for="basic_checkbox_4" className="mb-0 h-15"></label>
            <span className="text-line">Proin nec mi porta</span>
            <small className="badge bg-success"><i className="fa fa-clock-o"></i> 3 days</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5 by-1">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_5" className="filled-in"/>
            <label for="basic_checkbox_5" className="mb-0 h-15"></label>
            <span className="text-line">Maecenas scelerisque</span>
            <small className="badge bg-primary"><i className="fa fa-clock-o"></i> 1 week</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_6" className="filled-in"/>
            <label for="basic_checkbox_6" className="mb-0 h-15"></label>
            <span className="text-line">Vivamus nec orci</span>
            <small className="badge bg-info"><i className="fa fa-clock-o"></i> 1 month</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5 by-1">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_7" className="filled-in"/>
            <label for="basic_checkbox_7" className="mb-0 h-15"></label>
            {/* <!-- todo text --> */}
            <span className="text-line">Nulla vitae purus</span>
            {/* <!-- Emphasis label --> */}
            <small className="badge bg-danger"><i className="fa fa-clock-o"></i> 2 mins</small>
            {/* <!-- General tools such as edit or delete--> */}
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_8" className="filled-in"/>
            <label for="basic_checkbox_8" className="mb-0 h-15"></label>
            <span className="text-line">Phasellus interdum</span>
            <small className="badge bg-info"><i className="fa fa-clock-o"></i> 4 hours</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5 by-1">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_9" className="filled-in"/>
            <label for="basic_checkbox_9" className="mb-0 h-15"></label>
            <span className="text-line">Quisque sodales</span>
            <small className="badge bg-warning"><i className="fa fa-clock-o"></i> 1 day</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
          <li className="py-15 px-5">
            {/* <!-- checkbox --> */}
            <input type="checkbox" id="basic_checkbox_10" className="filled-in"/>
            <label for="basic_checkbox_10" className="mb-0 h-15"></label>
            <span className="text-line">Proin nec mi porta</span>
            <small className="badge bg-success"><i className="fa fa-clock-o"></i> 3 days</small>
            <div className="tools">
              <i className="fa fa-edit"></i>
              <i className="fa fa-trash-o"></i>
            </div>
          </li>
        </ul>
    </div>
    {/* <!-- /.tab-pane --> */}
  </div>
</aside>
{/* <!-- /.control-sidebar --> */}

{/* <!-- Add the sidebar's background. This div must be placed immediately after the control sidebar --> */}
<div className="control-sidebar-bg"></div>

</div>
{/* <!-- ./wrapper --> */}


    </div>
  )
}

export default Home