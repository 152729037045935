import React from 'react'

function Nav() {
  return (
    <div>
        
        <nav className="main-nav" role="navigation">

    {/* <!-- Mobile menu toggle button (hamburger/x icon) --> */}
    <input id="main-menu-state" type="checkbox"/>
    <label className="main-menu-btn" for="main-menu-state">
      <span className="main-menu-btn-icon"></span> Toggle main menu visibility
    </label>

    {/* <!-- Sample menu definition --> */}
    <ul id="main-menu" className="sm sm-blue">
      <li><a href="index.html"><i className="icon-Layout-4-blocks"><span className="path1"></span><span className="path2"></span></i>Dashboard</a></li>
      <li><a href="#"><i className="icon-Equalizer"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>Reports</a>
          <ul> 
              <li><a href="reports_transactions.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Transactions Reports</a></li>
              <li><a href="reports_top_gainers_losers.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Top Gainers/Losers</a></li>
              <li><a href="reports_market_capitalizations.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Market Capitalizations</a></li>
              <li><a href="reports_crypto_stats.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Crypto Stats</a></li>
                              
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Transactions</a>
                <ul>
                  <li><a href="transactions_tables.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Transactions Tables</a></li>
                  <li><a href="transaction_search.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Transactions Search</a></li>	
                  <li><a href="transaction_details.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Single Transaction</a></li>
                  <li><a href="transactions_counter.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Transactions Counter</a></li>	
                </ul>			  
              </li>
          </ul>
      </li>
      <li><a href="#"><i className="icon-Chart-pie"><span className="path1"></span><span className="path2"></span></i>ICO</a>
          <ul> 
              <li><a href="ico_distribution_countdown.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Countdown</a></li>
              <li><a href="ico_roadmap_timeline.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Roadmap/Timeline</a></li>
              <li><a href="ico_progress.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Progress Bar</a></li>
              <li><a href="ico_details.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Details</a></li>
              <li><a href="ico_listing.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>ICO Listing</a></li>
              <li><a href="ico_filter.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>ICO Listing - Filters</a></li>	
          </ul>
      </li>
      <li><a href="currency_exchange.html"><i className="icon-Repeat"><span className="path1"></span><span className="path2"></span></i>Currency</a></li> 
      <li><a href="#"><i className="icon-Group"><span className="path1"></span><span className="path2"></span></i>Members</a>
          <ul> 
              <li><a href="members.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Members Grid</a></li>
              <li><a href="members_list.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Members List</a></li>
              <li><a href="member_profile.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Member Profile</a></li>	
          </ul>
      </li> 
      <li><a href="#"><i className="icon-Arrows-h"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>Tickers</a>
          <ul> 
              <li><a href="tickers.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Ticker</a></li>
              <li><a href="tickers_live_pricing.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Live Crypto Prices</a></li>	
          </ul>
      </li>
      <li><a href="#"><i className="icon-Chart-line"><span className="path1"></span><span className="path2"></span></i>Charts</a>
          <ul> 
              <li><a href="charts_chartjs.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>ChartJS</a></li>
              <li><a href="charts_flot.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Flot</a></li>
              <li><a href="charts_inline.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Inline charts</a></li>
              <li><a href="charts_morris.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Morris</a></li>
              <li><a href="charts_peity.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Peity</a></li>
              <li><a href="charts_chartist.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Chartist</a></li>
              <li><a href="charts_rickshaw.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Rickshaw Charts</a></li>
              <li><a href="charts_nvd3.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>NVD3 Charts</a></li>
              <li><a href="charts_echart.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>eChart</a></li>
              <li><a href="charts_amcharts.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>amCharts Charts</a></li>
              <li><a href="charts_amstock_charts.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>amCharts Stock Charts</a></li>
              <li><a href="charts_ammaps.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>amCharts Maps</a></li>
          </ul>
      </li>
      <li><a href="#"><i className="icon-Flower"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>Apps</a>
          <ul> 
              <li><a href="app_chat.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Chat app</a></li>
              <li><a href="app_contact.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Contact / Employee</a></li>
              <li><a href="app_userlist_grid.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Userlist Grid</a></li>
              <li><a href="app_userlist.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Userlist</a></li>
              <li><a href="app_ticket.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Support Ticket</a></li>
              <li><a href="app_calendar.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Calendar</a></li>
              <li><a href="app_profile.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Profile</a></li>
              <li><a href="app_taskboard.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Project DashBoard</a></li>
              <li><a href="app_project_table.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Project</a></li>				
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Mailbox</a>
                <ul>
                  <li><a href="mailbox_inbox.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Inbox</a></li>
                  <li><a href="mailbox_compose.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Compose</a></li>
                  <li><a href="mailbox_read_mail.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Read</a></li>
                </ul>			  
              </li>
          </ul>
      </li>
      <li><a href="#"><i className="icon-Bucket"><span className="path1"></span><span className="path2"></span></i>UI</a>
          <ul> 
              <li><a href="ui_badges.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Badges</a></li>
              <li><a href="ui_border_utilities.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Border</a></li>
              <li><a href="ui_buttons.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Buttons</a></li>	
              <li><a href="ui_color_utilities.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Color</a></li>
              <li><a href="ui_dropdown.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Dropdown</a></li>
              <li><a href="ui_dropdown_grid.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Dropdown Grid</a></li>
              <li><a href="ui_typography.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Typography</a></li>
              <li><a href="ui_progress_bars.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Progress Bars</a></li>
              <li><a href="ui_grid.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Grid</a></li>
              <li><a href="ui_ribbons.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Ribbons</a></li>
              <li><a href="ui_sliders.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Sliders</a></li>
              <li><a href="ui_tab.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Tabs</a></li>
              <li><a href="ui_timeline.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Timeline</a></li>
              <li><a href="ui_timeline_horizontal.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Horizontal Timeline</a></li>
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Components</a>
                <ul>
                  <li><a href="component_bootstrap_switch.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Bootstrap Switch</a></li>
                  <li><a href="component_date_paginator.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Date Paginator</a></li>
                  <li><a href="component_media_advanced.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Advanced Medias</a></li>
                  <li><a href="component_modals.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Modals</a></li>
                  <li><a href="component_nestable.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Nestable</a></li>
                  <li><a href="component_notification.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Notification</a></li>
                  <li><a href="component_portlet_draggable.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Draggable Portlets</a></li>
                  <li><a href="component_sweatalert.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Sweet Alert</a></li>
                  <li><a href="component_rangeslider.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Range Slider</a></li>
                  <li><a href="component_rating.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Ratings</a></li>
                  <li><a href="component_animations.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Animations</a></li>
                </ul>			  
              </li>
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Box Cards</a>
                <ul>
                  <li><a href="box_cards.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>User Card</a></li>
                  <li><a href="box_advanced.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Advanced Card</a></li>
                  <li><a href="box_basic.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Basic Card</a></li>
                  <li><a href="box_color.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Card Color</a></li>
                  <li><a href="box_group.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Card Group</a></li>
                </ul>			  
              </li>
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Icons</a>
                <ul>
                  <li><a href="icons_fontawesome.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Font Awesome</a></li>
                  <li><a href="icons_glyphicons.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Glyphicons</a></li>
                  <li><a href="icons_material.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Material Icons</a></li>	
                  <li><a href="icons_themify.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Themify Icons</a></li>
                  <li><a href="icons_simpleline.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Simple Line Icons</a></li>
                  <li><a href="icons_cryptocoins.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Cryptocoins Icons</a></li>
                  <li><a href="icons_flag.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Flag Icons</a></li>
                  <li><a href="icons_weather.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Weather Icons</a></li>
                </ul>			  
              </li>
          </ul>
      </li>
      <li><a href="#"><i className="icon-Layers"><span className="path1"></span><span className="path2"></span></i>Widgets</a>
          <ul> 
              <li><a href="widgets_blog.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Blog</a></li>
              <li><a href="widgets_chart.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Chart</a></li>
              <li><a href="widgets_list.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>List</a></li>
              <li><a href="widgets_social.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Social</a></li>
              <li><a href="widgets_statistic.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Statistic</a></li>
              <li><a href="widgets_weather.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Weather</a></li>
              <li><a href="widgets.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Widgets</a></li>
              <li><a href="email_index.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Emails</a></li>				
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Maps</a>
                <ul>
                  <li><a href="map_google.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Google Map</a></li>
                  <li><a href="map_vector.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Vector Map</a></li>
                </ul>			  
              </li>		
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Extension</a>
                <ul>
                  <li><a href="extension_fullscreen.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Fullscreen</a></li>
                  <li><a href="extension_pace.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Pace</a></li>
                </ul>			  
              </li>
          </ul>
      </li>
      <li><a href="#"><i className="icon-File"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>Forms & Table</a>
          <ul> 			
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Forms</a>
                <ul>
                  <li><a href="forms_advanced.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Advanced Elements</a></li>
                  <li><a href="forms_code_editor.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Code Editor</a></li>
                  <li><a href="forms_editor_markdown.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Markdown</a></li>
                  <li><a href="forms_editors.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Editors</a></li>
                  <li><a href="forms_validation.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Form Validation</a></li>
                  <li><a href="forms_wizard.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Form Wizard</a></li>
                  <li><a href="forms_general.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>General Elements</a></li>
                  <li><a href="forms_mask.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Formatter</a></li>
                  <li><a href="forms_xeditable.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Xeditable Editor</a></li>
                  <li><a href="forms_dropzone.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Dropzone</a></li>
                </ul>			  
              </li>		
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Tables</a>
                <ul>
                  <li><a href="tables_simple.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Simple tables</a></li>
                  <li><a href="tables_data.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Data tables</a></li>
                  <li><a href="tables_editable.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Editable Tables</a></li>
                  <li><a href="tables_color.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Table Color</a></li>
                </ul>			  
              </li>
          </ul>
      </li>
      <li><a href="#"><i className="icon-Duplicate"><span className="path1"></span><span className="path2"></span></i>Pages</a>
          <ul> 			
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Authentication</a>
                <ul>
                  <li><a href="auth_login.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Login</a></li>
                  <li><a href="auth_register.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Register</a></li>
                  <li><a href="auth_lockscreen.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Lockscreen</a></li>
                  <li><a href="auth_user_pass.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Recover password</a></li>
                </ul>			  
              </li>		
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Error Pages</a>
                <ul>
                  <li><a href="error_404.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Error 404</a></li>
                  <li><a href="error_500.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Error 500</a></li>
                  <li><a href="error_maintenance.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Maintenance</a></li>
                </ul>			  
              </li>	
              <li><a href="#"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Sample Pages</a>
                <ul>
                  <li><a href="sample_blank.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Blank</a></li>
                  <li><a href="sample_coming_soon.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Coming Soon</a></li>
                  <li><a href="sample_custom_scroll.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Custom Scrolls</a></li>
                  <li><a href="sample_faq.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>FAQ</a></li>
                  <li><a href="sample_gallery.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Gallery</a></li>
                  <li><a href="sample_lightbox.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Lightbox Popup</a></li>
                  <li><a href="sample_pricing.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Pricing</a></li>
                  <li><a href="sample_invoice.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Invoice</a></li>
                  <li><a href="sample_invoicelist.html"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Invoice List</a></li>
                </ul>			  
              </li>
          </ul>
      </li>
    </ul>
  </nav>

    </div>
  )
}

export default Nav