import React from 'react'

function Header() {
  return (
    <div>
        
        <header className="main-header shadow" style={{ background: "linear-gradient(135deg, #001172, rgb(0 17 110))" }}>
    <div className="inside-header">
        <div className="d-flex align-items-center logo-box justify-content-between">	
          <a href="index.html" className="logo">			  
            <div className="logo-mini">
                <span className="light-logo"><img src="../images/logo-dark.png" alt="logo"/></span>
                {/* <!-- <span className="dark-logo"><img src="../images/logo-dark.png" alt="logo"/></span> --> */}
            </div>
            <div className="logo-lg">
                {/* <!-- <span className="light-logo"><img src="../images/logo-dark-text.png" alt="logo"></span> --> */}
                {/* <!-- <span className="dark-logo"><img src="../images/logo-light-text.png" alt="logo"></span> --> */}
                <span className="text-white text-bold text-uppercase font-size-14"> TELECOMPLUG </span>
            </div>
          </a>	
        </div>
        
        
      {/* Header Navbar  */}
      <nav className="navbar navbar-static-top" style={{ background: "white" }}>
        {/* Sidebar toggle button */}
        <div>		  
            <a href="#" data-provide="fullscreen" className="sidebar-toggle d-lg-inline-flex d-none" title="Full Screen">
              <i className="mdi mdi-crop-free"></i>
            </a> 

        </div>

        <div className="navbar-custom-menu r-side">
          <ul className="nav navbar-nav">
            {/* <!-- full Screen --> */}
            <li className="search-bar">		  
                <div className="lookup lookup-circle lookup-right">
                   <input type="text" name="s"/>
                </div>
            </li>			
            {/* <!-- Messages --> */}
            <li className="dropdown messages-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" title="Messages">
                <i className="mdi mdi-email"></i>
              </a>
              <ul className="dropdown-menu animated bounceIn">

                <li className="header">
                  <div className="p-20">
                      <div className="flexbox">
                          <div>
                              <h4 className="mb-0 mt-0">Messages</h4>
                          </div>
                          <div>
                              <a href="#" className="text-danger">Clear All</a>
                          </div>
                      </div>
                  </div>
                </li>
                <li>
                  {/* <!-- inner menu: contains the actual data --> */}
                  <ul className="menu sm-scrol">
                    <li>
                        {/* <!-- start message --> */}
                      <a href="#">
                        <div className="pull-left">
                          <img src="../images/user2-160x160.jpg" className="rounded-circle" alt="User Image" />
                        </div>
                        <div className="mail-contnet">
                           <h4>
                            Lorem Ipsum
                            <small><i className="fa fa-clock-o"></i> 15 mins</small>
                           </h4>
                           <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                        </div>
                      </a>
                    </li>
                    {/* <!-- end message --> */}
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img src="../images/user3-128x128.jpg" className="rounded-circle" alt="User Image"/>
                        </div>
                        <div className="mail-contnet">
                           <h4>
                            Nullam tempor
                            <small><i className="fa fa-clock-o"></i> 4 hours</small>
                           </h4>
                           <span>Curabitur facilisis erat quis metus congue viverra.</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img src="../images/user4-128x128.jpg" className="rounded-circle" alt="User Image"/>
                        </div>
                        <div className="mail-contnet">
                           <h4>
                            Proin venenatis
                            <small><i className="fa fa-clock-o"></i> Today</small>
                           </h4>
                           <span>Vestibulum nec ligula nec quam sodales rutrum sed luctus.</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img src="../images/user3-128x128.jpg" className="rounded-circle" alt="User Image"/>
                        </div>
                        <div className="mail-contnet">
                           <h4>
                            Praesent suscipit
                          <small><i className="fa fa-clock-o"></i> Yesterday</small>
                           </h4>
                           <span>Curabitur quis risus aliquet, luctus arcu nec, venenatis neque.</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="pull-left">
                          <img src="../images/user4-128x128.jpg" className="rounded-circle" alt="User Image"/>
                        </div>
                        <div className="mail-contnet">
                           <h4>
                            Donec tempor
                            <small><i className="fa fa-clock-o"></i> 2 days</small>
                           </h4>
                           <span>Praesent vitae tellus eget nibh lacinia pretium.</span>
                        </div>

                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">				  
                    <a href="#">See all e-Mails</a>
                </li>
              </ul>
            </li>
            {/* <!-- Notifications --> */}
            <li className="dropdown notifications-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" title="Notifications">
                <i className="mdi mdi-bell"></i>
              </a>
              <ul className="dropdown-menu animated bounceIn">

                <li className="header">
                  <div className="p-20">
                      <div className="flexbox">
                          <div>
                              <h4 className="mb-0 mt-0">Notifications</h4>
                          </div>
                          <div>
                              <a href="#" className="text-danger">Clear All</a>
                          </div>
                      </div>
                  </div>
                </li>

                <li>
                  {/* <!-- inner menu: contains the actual data --> */}
                  <ul className="menu sm-scrol">
                    <li>
                      <a href="#">
                        <i className="fa fa-users text-info"></i> Curabitur id eros quis nunc suscipit blandit.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-warning text-warning"></i> Duis malesuada justo eu sapien elementum, in semper diam posuere.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-users text-danger"></i> Donec at nisi sit amet tortor commodo porttitor pretium a erat.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-shopping-cart text-success"></i> In gravida mauris et nisi
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-danger"></i> Praesent eu lacus in libero dictum fermentum.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-primary"></i> Nunc fringilla lorem 
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-success"></i> Nullam euismod dolor ut quam interdum, at scelerisque ipsum imperdiet.
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                    <a href="#">View all</a>
                </li>
              </ul>
            </li>	


            {/* <!-- Tasks --> */}
            <li className="dropdown messages-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" title="Tasks">
                <i className="mdi mdi-comment-alert"></i>
              </a>
              <ul className="dropdown-menu animated bounceIn">

                <li className="header">
                  <div className="p-20">
                      <div className="flexbox">
                          <div>
                              <h4 className="mb-0 mt-0">You have 6 tasks</h4>
                          </div>
                          <div>
                              <a href="#" className="text-danger">Clear All</a>
                          </div>
                      </div>
                  </div>
                </li>
                <li>


                  {/* <!-- inner menu: contains the actual data --> */}
                  <ul className="menu sm-scrol">
                    <li>
                      
                      {/* <!-- Task item --> */}
                      <a href="#">
                        <h6>
                          Lorem ipsum dolor sit amet
                          <small className="pull-right">30%</small>
                        </h6>
                        <div className="progress xs mb-0">
                          <div className="progress-bar progress-bar-primary" style={{ width: "30%" }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                            <span className="sr-only">30% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    {/* <!-- end task item --> */}

                    <li>
                      
                      {/* <!-- Task item --> */}

                      <a href="#">
                        <h6>
                          Vestibulum nec ligula
                          <small className="pull-right">20%</small>
                        </h6>
                        <div className="progress xs mb-0">
                          <div className="progress-bar progress-bar-danger" style={{ width: "20%" }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                            <span className="sr-only">20% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    {/* <!-- end task item --> */}
                    <li>
                      {/* <!-- Task item --> */}
                      <a href="#">
                        <h6>
                          Donec id leo ut ipsum
                          <small className="pull-right">70%</small>
                        </h6>
                        <div className="progress xs mb-0">
                          <div className="progress-bar progress-bar-info" style={{ width: "70%" }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                            <span className="sr-only">70% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    {/* <!-- end task item --> */}
                    <li>
                      
                      {/* <!-- Task item --> */}
                      <a href="#">
                        <h6>
                          Praesent vitae tellus
                          <small className="pull-right">40%</small>
                        </h6>
                        <div className="progress xs mb-0">
                          <div className="progress-bar progress-bar-warning" style={{ width: "40%" }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                            <span className="sr-only">40% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    {/* <!-- end task item --> */}
                    <li>
                      
                      {/* <!-- Task item --> */}
                      <a href="#">
                        <h6>
                          Nam varius sapien
                          <small className="pull-right">80%</small>
                        </h6>
                        <div className="progress xs mb-0">
                          <div className="progress-bar progress-bar-danger" style={{ width: "80%" }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                            <span className="sr-only">80% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    {/* <!-- end task item --> */}
                    <li>
                      
                      {/* <!-- Task item --> */}
                      <a href="#">
                        <h6>
                          Nunc fringilla
                          <small className="pull-right">90%</small>
                        </h6>
                        <div className="progress xs mb-0">
                          <div className="progress-bar progress-bar-primary" style={{ width: "90%" }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                            <span className="sr-only">90% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">				  
                    <a href="#">View all tasks</a>
                </li>
              </ul>
            </li>	

            {/* <!-- User Account--> */}

            <li className="dropdown user user-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" title="User">
                <img src="../images/avatar/7.jpg" className="user-image rounded-circle" alt="User Image"/>
              </a>
              <ul className="dropdown-menu animated flipInX">
                {/* <!-- User image --> */}
                <li className="user-header bg-img" style={{ backgroundImage: "url(../images/user-info.jpg)" }} data-overlay="3">
                    <div className="flexbox align-self-center">					  
                      <img src="../images/avatar/7.jpg" className="float-left rounded-circle" alt="User Image"/>					  
                      <h4 className="user-name align-self-center">
                        <span>Samuel Brus</span>
                        <small>samuel@gmail.com</small>
                      </h4>
                    </div>
                </li>
                {/* <!-- Menu Body --> */}
                <li className="user-body">
                      <a className="dropdown-item" href="javascript:void(0)"><i className="ion ion-person"></i> My Profile</a>
                      <a className="dropdown-item" href="javascript:void(0)"><i className="ion ion-bag"></i> My Balance</a>
                      <a className="dropdown-item" href="javascript:void(0)"><i className="ion ion-email-unread"></i> Inbox</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="javascript:void(0)"><i className="ion ion-settings"></i> Account Setting</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="javascript:void(0)"><i className="ion-log-out"></i> Logout</a>
                      <div className="dropdown-divider"></div>
                      <div className="p-10"><a href="javascript:void(0)" className="btn btn-sm btn-rounded btn-success">View Profile</a></div>
                </li>
              </ul>
            </li>	


            {/* <!-- Control Sidebar Toggle Button --> */}
            <li>
              <a href="#" data-toggle="control-sidebar" title="Setting"><i className="fa fa-cog fa-spin"></i></a>
            </li>

          </ul>
        </div>
      </nav>
    </div>
</header>

    </div>
  )
}

export default Header